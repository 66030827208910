import { Action, Dispatch } from 'redux'
import { GET_USER_TYPE } from '../types'
import { getUser } from '../../api/get-user.api'
import localStorageService from '../../services/local-storage.service'
import { KeyStorage } from '../../models'
import { IUser } from '../../models/user.model'

const { GET_USER_SUCCESS, GET_USER_REQUEST, GET_USER_FAILURE } = GET_USER_TYPE

const userDetailsPayload = (userDetails: IUser) => ({
  type: GET_USER_SUCCESS,
  payload: userDetails,
})

export const getUserAction = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: GET_USER_REQUEST })
    return getUser()
      .then((response) => {
        const userData = response

        const activeFacilityUnitUserRole = response?.residentUserRoles?.filter(
          (residentUserRole: any) => !!residentUserRole?.facilityUnit?.meter,
        )?.[0]

        const activeFacilityUnitId = activeFacilityUnitUserRole?.facilityUnit?.id || ''
        const currentFacilityUnitUserRoleFromStorage = localStorageService().get(
          KeyStorage.activeFacilityUnitUserRole,
        )
        if (
          !(
            currentFacilityUnitUserRoleFromStorage &&
            Object.keys(currentFacilityUnitUserRoleFromStorage).length > 0
          )
        ) {
          localStorageService().store(KeyStorage.activeFacilityUnitId, activeFacilityUnitId)
          localStorageService().store(
            KeyStorage.activeFacilityUnitUserRole,
            activeFacilityUnitUserRole,
          )
        }
        localStorageService().store(KeyStorage.user, userData)
        return dispatch(userDetailsPayload(response))
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_FAILURE,
          payload: error,
        })
      })
  }
}
