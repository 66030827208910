import React, { ReactElement } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'

import { env } from './utils'
import ErrorBoundary from './hocs/ErrorBoundary'
import configureStore from './redux/store'

import Routes from './routes'
import './App.scss'

const environment = env.DEPLOYMENT_ENV || 'development'

Sentry.init({
  dsn: env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment,
})

const store = configureStore()

function App(): ReactElement {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </Provider>
  )
}

export default App
