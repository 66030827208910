import { instance } from '../utils/axios-instance.util'
import { deserialize } from '../utils/json-api.util'

import { KeyStorage } from '../models'
import { localStorageService } from '../services'

export const getFacilityUnit = async () => {
  const facilityUnitId = localStorageService().get(KeyStorage.activeFacilityUnitId)

  const axiosInstance = instance()
  const { data } = await axiosInstance.get(`v2/residents/facilityUnits/${facilityUnitId}`)
  const result = await deserialize(data)
  return result
}
