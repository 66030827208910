import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUserAction } from '../../redux/actions'
import { IUser } from '../../models/user.model'
import { GET_USER_TYPE } from '../../redux/types'
import { auth0Service } from '../../services'
import Loader from '../../components/Loader'
import { trackAmplitudeEvent } from '../../utils/amplitude-event.util'

interface IAuthCallbackProps {
  getUserInfo: () => Promise<void>
  userDataLoading: boolean
}

const { GET_USER_SUCCESS, GET_USER_FAILURE } = GET_USER_TYPE

const auth0 = auth0Service()

const AuthCallback: React.FC<IAuthCallbackProps> = ({
  getUserInfo,
  userDataLoading,
}: IAuthCallbackProps) => {
  const { Text } = Typography
  const history = useHistory()

  useEffect(() => {
    const handleUserInfo = () => {
      getUserInfo().then(async (response: any) => {
        if (response?.type === GET_USER_SUCCESS) {
          trackAmplitudeEvent('navigated to dashboard')
          history.push('/dashboard')
        } else if (response?.type === GET_USER_FAILURE) {
          trackAmplitudeEvent('navigated to landing - unauthorized user')
          auth0.logout({ unauthorized: true })
        }
      })
    }
    handleUserInfo()
  }, [])

  return userDataLoading ? <Loader data-testid="auth-loader" /> : null
}

const mapDispatchToProps = (dispatch: any) => ({
  getUserInfo: () => dispatch(getUserAction()),
})

const mapStateToProps = ({
  getUserReducer,
}: {
  getUserReducer: { userDetails: IUser; isLoading: boolean }
}) => {
  const { userDetails, isLoading: userDataLoading } = getUserReducer

  return {
    userDetails,
    userDataLoading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallback)
