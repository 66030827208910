import { instance } from '../utils/axios-instance.util'
import { deserialize } from '../utils/json-api.util'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'
import { IInitializePaymentOption } from '../models/initialize-payment.model'

export const initializePayment = async (options: IInitializePaymentOption) => {
  const facilityUnitId = localStorageService().get(KeyStorage.activeFacilityUnitId)
  const axiosInstance = instance()
  const { data } = await axiosInstance.post(
    `residents/facilityUnits/${facilityUnitId}/payments/initialize`,
    options,
  )

  const result = await deserialize(data)
  return result
}
