import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Typography, Space, Row, Col, Alert, Divider } from 'antd'

import axios, { CancelToken } from 'axios'
import { ModalContentType } from '../../../models/modal.model'
import {
  calculateLineItemAction,
  setProcessingFee as setProcessingFeeFnc,
} from '../../../redux/actions'
import ModalContent from '../../Dashboard/ModalContent'
import CustomModal from '../../../components/CustomModal'
import { PaymentSetupStep } from '../../Dashboard/Dashboard.metadata'

import { IFacilityUnit, ICalculateLineItemOptions } from '../../../models/facility-unit.model'

import { trackAmplitudeEventProperty } from '../../../utils/amplitude-event.util'
import { useMediaQueries } from '../../../hooks'

import { FLUTTERWAVE_LIMIT, HowToPayTypes } from '../../../utils/constant'
import './PaymentReview.styles.scss'
import { formatCurrency } from '../../../utils/formatMoney'
import PaymentReviewLoader from '../../../components/Loader/PaymentReviewLoader'

interface IPaymentReviewProps {
  calculateLineItem: any
  setProcessingFee: any
  communityFeeGlobal: number
  energyCreditFee: number
  communityFeeCheckBoxState: boolean
  energyCreditsCheckBoxState: boolean
  processingFee: number
  howToPay: string
  facilityUnit: any
  paymentSetupStep: number
}

const PaymentReview = ({
  calculateLineItem,
  setProcessingFee,
  communityFeeGlobal,
  energyCreditFee,
  communityFeeCheckBoxState,
  energyCreditsCheckBoxState,
  processingFee,
  facilityUnit,
  paymentSetupStep,
  howToPay,
}: IPaymentReviewProps): ReactElement => {
  const { Text, Title } = Typography
  const { isDashboardMobile, isMobileOrTablet } = useMediaQueries()
  const [reload, setReload] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContentType>({
    title: '',
    body: [],
  })

  const startingEnergyCreditCents = facilityUnit?.currentMeterState?.usageCreditBalanceCents

  const sumTotalIncent = () => (energyCreditFee + communityFeeGlobal) * 100 + processingFee

  const getServiceCharge = (source?: { token: CancelToken }): void => {
    setReload(true)
    const subTotal = Math.round((energyCreditFee + communityFeeGlobal) * 100)

    calculateLineItem({ baseAmountCents: subTotal, paymentProcessor: 'flutterwave' }, source).then(
      (response: any) => {
        if (!response) {
          // Todo: handle no response
          setProcessingFee(0)
          setReload(false)
        } else {
          setProcessingFee(response?.payload?.serviceChargesCents)
          setReload(false)
        }
      },
    )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()

    if (
      howToPay === HowToPayTypes.NewPayment &&
      paymentSetupStep === PaymentSetupStep.PaymentReview
    ) {
      getServiceCharge(source)
    }
  }, [paymentSetupStep])

  return (
    <>
      <CustomModal
        showModal={showModal}
        onOk={() => {
          setShowModal(false)
        }}
        onCancel={() => {
          setShowModal(false)
        }}
        style={
          !isMobileOrTablet
            ? {
                marginLeft: '30%',
                borderRadius: '8px',
                padding: '24px 5px 50px',
                marginTop: '5%',
              }
            : {
                marginLeft: '5%',
              }
        }
      >
        <ModalContent title={modalContent.title} body={modalContent.body} />
      </CustomModal>

      <Title
        level={4}
        className="header-text"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        Review
      </Title>
      <Title
        level={5}
        className="sub-heading-text_"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        This transaction is not complete yet. Proceed to complete the transaction.
      </Title>

      <br />
      <br />

      {reload ? (
        <PaymentReviewLoader />
      ) : (
        <>
          <Row gutter={1} justify="start">
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-left">
              <Text className="">Energy credits</Text>
            </Col>
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-right">
              <Text className="right-amount-text">{formatCurrency(energyCreditFee * 100)}</Text>
            </Col>
          </Row>
          <br />
          <Row gutter={1} justify="start">
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-left">
              <Text className="">Estate/community fees</Text>
            </Col>
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-right">
              <Text className="right-amount-text">{formatCurrency(communityFeeGlobal * 100)}</Text>
            </Col>
          </Row>
          <br />
          <Row gutter={1} justify="start">
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-left">
              <Text className="">Processing fee</Text>
              <br />
              <Text
                className="learn-more-text"
                onClick={() => {
                  return (
                    setShowModal(true),
                    setModalContent({
                      title: 'What are Processing Fees?',
                      body: [
                        'Transaction fees are the fees required to ensure simple and easy online payments. They may include the secured Payment Processing companies fees, CBN imposed fees or platform transaction fees.',
                      ],
                    })
                  )
                }}
              >
                Learn more
              </Text>
            </Col>
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-right">
              <Text className="right-amount-text">{formatCurrency(processingFee)}</Text>
            </Col>
          </Row>

          {/*  */}

          <Row gutter={1}>
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-left">
              <Space direction="vertical" align="end" className="sub-total-block">
                <Text className="total-text">Total</Text>
              </Space>
            </Col>
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6} className="review-block-right">
              <Space direction="vertical" align="end" className="sub-total-block">
                <Text className="total-value">{formatCurrency(sumTotalIncent())}</Text>
              </Space>
            </Col>
          </Row>

          {sumTotalIncent() / 100 > FLUTTERWAVE_LIMIT / 100 &&
            howToPay === HowToPayTypes.NewPayment && (
              <Row gutter={30} justify="start">
                <Col
                  xs={24}
                  sm={24}
                  md={{ span: 24 }}
                  lg={12}
                  xl={12}
                  offset={isDashboardMobile ? 1 : 1}
                >
                  {' '}
                  <Alert
                    showIcon={false}
                    type="error"
                    message={`The maximum transaction amount is ${formatCurrency(
                      FLUTTERWAVE_LIMIT,
                    )}`}
                    banner
                    className="alert-error-div"
                  />
                </Col>
              </Row>
            )}

          {howToPay === HowToPayTypes.EnergyCredit && (
            <>
              <Row gutter={1} justify="start">
                <Col
                  xs={24}
                  sm={24}
                  md={{ span: 24 }}
                  lg={12}
                  xl={12}
                  className="review-box-transfer-payment"
                >
                  <Text className="energy-credit-header">TRANSACTION SUMMARY (ENERGY CREDITS)</Text>
                  <br />
                  <br />
                  <Row gutter={1} justify="center">
                    <Col span={12} className="review-block-left">
                      <Text className="">Starting Energy Credits</Text>
                    </Col>
                    <Col span={12} className="review-block-right">
                      <Text className="right-amount-text">
                        {formatCurrency(startingEnergyCreditCents)}
                      </Text>
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={1} justify="center">
                    <Col span={14} className="review-block-left">
                      <Text className="">Estate/Community Fee Payment</Text>
                    </Col>
                    <Col span={10} className="review-block-right">
                      <Text className="right-amount-text">{formatCurrency(sumTotalIncent())}</Text>
                    </Col>
                  </Row>

                  <Divider />

                  <Row gutter={1} justify="center">
                    <Col span={14} className="review-block-left">
                      <Text className="review-bold">Ending Credit Balance</Text>
                    </Col>
                    <Col span={10} className="review-block-right">
                      <Text className="right-amount-text review-bold">
                        {formatCurrency(startingEnergyCreditCents - sumTotalIncent())}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <br />
              <br />
              {startingEnergyCreditCents - sumTotalIncent() < 100 && (
                <Row gutter={30} justify="start">
                  <Col
                    xs={24}
                    sm={24}
                    md={{ span: 24 }}
                    lg={12}
                    xl={12}
                    offset={isDashboardMobile ? 1 : 1}
                  >
                    {' '}
                    <Alert
                      showIcon={false}
                      type="error"
                      message="Insufficient credits. Kindly make a new payment to op up your credit and/or pay your fees.
            You can select Back to change the payment method as well.  "
                      banner
                      className="alert-error-div"
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setProcessingFee: (processingFee: number) => dispatch(setProcessingFeeFnc(processingFee)),
  calculateLineItem: (
    options: ICalculateLineItemOptions,
    source: { token: CancelToken; cancel: () => void },
  ) => dispatch(calculateLineItemAction(options, source)),
})

const mapStateToProps = ({
  paymentValuesReducer,
  setStepReducer,
  getFacilityUnitReducer,
}: {
  setStepReducer: {
    communityFeeCheckBoxState: boolean
    energyCreditsCheckBoxState: boolean
    howToPay: string
    paymentSetupStep: number
  }
  paymentValuesReducer: { communityFee: number; energyCreditFee: number; processingFee: number }
  getFacilityUnitReducer: { facilityUnit: IFacilityUnit; isLoading: boolean }
}) => {
  const {
    communityFeeCheckBoxState,
    energyCreditsCheckBoxState,
    howToPay,
    paymentSetupStep,
  } = setStepReducer
  const { communityFee: communityFeeGlobal, energyCreditFee, processingFee } = paymentValuesReducer
  const { facilityUnit, isLoading: facilityUnitLoading } = getFacilityUnitReducer

  return {
    communityFeeGlobal,
    howToPay,
    energyCreditFee,
    processingFee,
    communityFeeCheckBoxState,
    energyCreditsCheckBoxState,
    facilityUnit,
    paymentSetupStep,
    facilityUnitLoading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReview)
