import { Action, Dispatch } from 'redux'
import { getFacilityUnit } from '../../api/get-facility-unit.api'
import { GET_FACILITY_UNIT_TYPE } from '../types'

const {
  GET_FACILITY_UNIT_FAILURE,
  GET_FACILITY_UNIT_REQUEST,
  GET_FACILITY_UNIT_SUCCESS,
} = GET_FACILITY_UNIT_TYPE

const facilityUnitPayload = (facilityUnit: any) => ({
  type: GET_FACILITY_UNIT_SUCCESS,
  payload: facilityUnit,
})

export const facilityUnitAction = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: GET_FACILITY_UNIT_REQUEST })
    return getFacilityUnit()
      .then((response) => {
        const facilityUnitData = response
        return dispatch(facilityUnitPayload(facilityUnitData))
      })
      .catch((error) => {
        dispatch({
          type: GET_FACILITY_UNIT_FAILURE,
          payload: error,
        })
      })
  }
}
