import React from 'react'
import queryString from 'query-string'
import { IInitializePayment } from '../../models/initialize-payment.model'
import { FlutterwaveVerifyStatus } from '../../models/verify-payment.model'

interface IpaymentPortalIFrameProps {
  initializePaymentDetails: IInitializePayment
  setPaymentStatus: any
  handleFlutterwaveCancelAction: () => void
}

const PaymentPortalIFrame: React.FC<IpaymentPortalIFrameProps> = ({
  initializePaymentDetails,
  setPaymentStatus,
  handleFlutterwaveCancelAction,
}: IpaymentPortalIFrameProps) => {
  const onIframeNavigation = () => {
    const iframe = document.getElementById('paymentPortal') as HTMLIFrameElement
    try {
      const url = iframe?.contentWindow?.location?.href

      if (url?.includes('/payment/callback')) {
        const { query: flutterwaveQueryParams } = queryString.parseUrl(url)

        if (
          [
            FlutterwaveVerifyStatus.failed,
            FlutterwaveVerifyStatus.success,
            FlutterwaveVerifyStatus.pending,
          ].includes(flutterwaveQueryParams?.status as FlutterwaveVerifyStatus)
        ) {
          setPaymentStatus(flutterwaveQueryParams?.status as FlutterwaveVerifyStatus)
        } else if (flutterwaveQueryParams?.status === 'cancelled') {
          handleFlutterwaveCancelAction()
        }
      }
    } catch (err) {
      console.log(err.message, 'err')
    }
  }

  return (
    <iframe
      className="iframe-loader"
      seamless
      width="100%"
      height="100%"
      src={initializePaymentDetails.authorizationUrl}
      frameBorder="0"
      allow="clipboard-write; encrypted-media;"
      allowFullScreen
      title="Flutterwave Payment portal"
      loading="lazy"
      aria-hidden="true"
      name="paymentPortal"
      id="paymentPortal"
      sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-presentation"
      onLoad={onIframeNavigation}
      data-cy="payment-portal"
      data-testid="flutterwave-checkout"
    />
  )
}

export default PaymentPortalIFrame
