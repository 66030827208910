import * as Sentry from '@sentry/react'
import { KeyStorage } from '../models'
import { env } from '../utils'
import localStorageService from './local-storage.service'

export default function errorLoggingService() {
  const environment = env.DEPLOYMENT_ENV || 'development'

  const logError = (errorOrExceptionString: any) => {
    let exception: Error

    if (JSON.stringify(errorOrExceptionString) === '{}') {
      return
    }

    if ((errorOrExceptionString as any) instanceof Error) {
      exception = errorOrExceptionString as any
    } else {
      errorOrExceptionString =
        (errorOrExceptionString as any)?.error ||
        (typeof errorOrExceptionString === 'string'
          ? errorOrExceptionString
          : JSON.stringify(errorOrExceptionString))

      if (process.env.NODE_ENV !== 'test') {
        exception = new Error(errorOrExceptionString)
      }
    }

    if (environment === 'development' && process.env.NODE_ENV !== 'test') {
      console.log(errorOrExceptionString)
    }

    const userData = localStorageService().get(KeyStorage.user)
    const activeFacilityUnitId = localStorageService().get(KeyStorage.activeFacilityUnitId)

    Sentry.withScope((scope) => {
      scope.setTag('facilityUnit', activeFacilityUnitId)
      Sentry.setUser({ email: userData?.email })

      // Check for exception with response
      // Else check for exception without response( Network errors and silent it)

      if ((exception as any)?.response) {
        scope.setExtra('response', (exception as any)?.response)
        scope.setExtra('response.data', JSON.stringify((exception as any)?.response?.data))

        // Check for 401, 403 and set sentry severity to "Log" so that
        // it does not send to slack but notify us on Sentry.
        // This helps us reduce the noise on slack.
        if ([401, 403].includes((exception as any)?.response?.status)) {
          scope.setLevel(Sentry.Severity.Log)
        } else {
          scope.setLevel(Sentry.Severity.Error)
        }
      } else if (exception?.message === 'Network Error') {
        scope.setLevel(Sentry.Severity.Log)
      }

      Sentry.captureException(exception)
    })
  }

  return { logError }
}
