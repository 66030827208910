import React, { ReactElement, useEffect } from 'react'
import { Row, Col, Typography } from 'antd'
import { auth0Service, localStorageService } from '../../services'

import SuccessComponent from './SuccessComponent'
import FailureComponent from './FailureComponent'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'
import './ConfirmationPage.styles.scss'

interface IConfirmation {
  status: string
  setStepLevel: (number: number) => void
  cancel: () => void
  makeNewPayment: () => void
  onLogout: () => Promise<void>
}

const ConfirmationPage = ({
  status,
  setStepLevel,
  cancel,
  makeNewPayment,
  onLogout,
}: IConfirmation): ReactElement => {
  useEffect(() => setStepLevel(3))

  return (
    <Row className="confirmation-div">
      <Col>
        {status === 'completed' ? (
          <SuccessComponent cancel={() => makeNewPayment()} onLogout={onLogout} status={status} />
        ) : status === 'failed' ? (
          <FailureComponent
            cancel={() => makeNewPayment()}
            onLogout={onLogout}
            status={status}
            textBody="An error occured with your transaction. Please try again. Refer to the Help/FAQ
          article for tips on payments. Contact your property’s facility/power manager for further
          questions or comments."
          />
        ) : null}
      </Col>
    </Row>
  )
}

export default ConfirmationPage
