import axios, { CancelToken } from 'axios'
import { instance } from '../utils/axios-instance.util'
import { deserialize } from '../utils/json-api.util'
import { ICalculateLineItemOptions } from '../models/facility-unit.model'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'

export const calculateLineItem = async (
  options: ICalculateLineItemOptions,
  source: { token: CancelToken; cancel: () => void },
) => {
  const facilityUnitId =
    localStorageService().get(KeyStorage.activeFacilityUnitId) || options.facilityUnitId
  const { baseAmountCents, paymentProcessor } = options
  const axiosInstance = instance()
  const { data } = await axiosInstance.get(
    `residents/facilityUnits/${facilityUnitId}/payments/calculateLineItems?baseAmountCents=${baseAmountCents}&paymentProcessor=${paymentProcessor}`,
    {
      cancelToken: source?.token,
    },
  )

  source?.cancel()
  const result = await deserialize(data)
  return result
}
