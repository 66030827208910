/* eslint-disable */

import React, { Component, ErrorInfo, ReactNode } from 'react'
import { errorLoggingService } from '../services'
import { connect } from 'react-redux'

interface Props {
  children: ReactNode
  errorFromReducer: Error
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  // handle component rendering errors
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { error } = this.state
    const { children, errorFromReducer } = this.props

    const sentryError = errorFromReducer || error

    sentryError && errorLoggingService().logError(sentryError)
    return children
  }
}

const mapStateToProps = ({ errorReducer }: { errorReducer: any }) => {
  const { error: errorFromReducer } = errorReducer
  return {
    errorFromReducer,
  }
}

export default connect(mapStateToProps)(ErrorBoundary)
