export const FLUTTERWAVE_LIMIT = 250000000

export const TransactionStatus = {
  pending: 'pending',
  failed: 'failed',
  completed: 'completed',
  timedOut: 'timedOut',
  serverError: 'serverError',
}

export const HowToPayTypes = {
  EnergyCredit: 'energy-credit',
  NewPayment: 'new-payment',
}
