import React, { ReactElement, useEffect } from 'react'
import { Typography, Button } from 'antd'
import { ReactComponent as FailureImage } from '../../assets/failure.svg'
import CustomButton from '../../components/CustomButton'

import './ConfirmationPage.styles.scss'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'

interface IConfirmationComponent {
  cancel: () => void
  onLogout: () => Promise<void>
  textBody: string
  status?: string
}
const FailureComponent = ({
  cancel,
  onLogout,
  textBody,
  status,
}: IConfirmationComponent): ReactElement => {
  const { Text } = Typography

  useEffect(() => {
    if (textBody.includes('This transaction timed out')) {
      trackAmplitudeEventProperty('viewed checkout_timed_out')
    } else {
      trackAmplitudeEventProperty('viewed payment_verification')
      trackAmplitudeEventProperty('viewed payment_failure')
    }
  }, [status])

  const handleMakeAnotherPayment = () => {
    return cancel()
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <FailureImage data-testid="failure-image" style={{ marginLeft: '3%' }} />
      <Text className="confirmation-text">Oh no!</Text>
      <Text className="header-text-failure">{textBody}</Text>
      <div className="button">
        <CustomButton
          bgColor="purple"
          color="white"
          text="Try another payment"
          onClick={handleMakeAnotherPayment}
        />
      </div>
      <Button type="link" onClick={onLogout} className="logout-button">
        Logout
      </Button>
    </div>
  )
}

export default FailureComponent
