import { instance } from '../utils/axios-instance.util'
import { deserialize } from '../utils/json-api.util'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'

export const transferCredit = async (payload: { amountCents: number }) => {
  const id = localStorageService().get(KeyStorage.activeFacilityUnitId)
  const axiosInstance = instance()
  const { data } = await axiosInstance.post(
    `v1/residents/facilityUnits/${id}/transferCreditsToFees`,
    payload,
  )
  const result = await deserialize(data)
  return result
}
