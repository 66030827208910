import { PAYMENT_VALUES_TYPE } from '../types'

type PaymentValuesAction = {
  type: string
  payload: any
}

export type IPaymentValuesStateType = {
  communityFee: number
  energyCreditFee: number
  processingFee: number
  transactionStatus: string
}

export const INITIAL_STATE: IPaymentValuesStateType = {
  communityFee: 0,
  energyCreditFee: 0,
  processingFee: 0,
  transactionStatus: '',
}

const {
  SET_COMMUNITY_FEE,
  SET_ENERGY_CREDIT_FEE,
  SET_PROCESSING_FEE,
  SET_TRANSACTION_STATUS,
} = PAYMENT_VALUES_TYPE

export default (state = INITIAL_STATE, action: PaymentValuesAction) => {
  switch (action.type) {
    case SET_COMMUNITY_FEE:
      return { ...state, communityFee: action.payload }
    case SET_ENERGY_CREDIT_FEE:
      return { ...state, energyCreditFee: action.payload }
    case SET_PROCESSING_FEE:
      return { ...state, processingFee: action.payload }
    case SET_TRANSACTION_STATUS:
      return { ...state, transactionStatus: action.payload }
    default:
      return state
  }
}
