import { Auth0Client } from '@auth0/auth0-spa-js'
import localStorageService from './local-storage.service'
import * as env from '../utils/env.util'
import { KeyStorage } from '../models'

const localStorage = localStorageService()

export default function auth0Service() {
  const auth0 = new Auth0Client({
    domain: env.AUTH0_DOMAIN,
    client_id: env.AUTH0_CLIENTID,
    redirect_uri: env.AUTH0_REDIRECT_URL,
  })

  const handleRedirectCallback = async () => {
    await auth0.handleRedirectCallback()
  }

  const login = async () => {
    auth0.loginWithRedirect({
      redirect_uri: `${env.AUTH0_REDIRECT_URL}auth/callback`,
      audience: env.AUTH0_AUDIENCE,
      cacheLocation: (window as any).Cypress ? 'localstorage' : 'memory',
    })
  }

  const logout = (returnToParams?: { unauthorized: boolean }) => {
    localStorage.clear()
    auth0.logout({
      returnTo: returnToParams
        ? `${env.AUTH0_REDIRECT_URL}?unauthorized=${returnToParams?.unauthorized}`
        : env.AUTH0_REDIRECT_URL,
    })
  }

  const getAccessToken = async () => {
    const token = await auth0.getTokenSilently({ audience: env.AUTH0_AUDIENCE })
    return token
  }

  const isAuthenticated = async () => {
    await auth0.checkSession()
    const authStatus = await auth0.isAuthenticated()
    return authStatus
  }

  const setAccessTokenToLocalStorage = async () => {
    const accessToken = await getAccessToken()
    localStorage.store(KeyStorage.token, accessToken)
  }

  return {
    login,
    logout,
    getAccessToken,
    setAccessTokenToLocalStorage,
    isAuthenticated,
    handleRedirectCallback,
  }
}
