// Todo set a state that will follow old new payment processing if the NewPayment type is selected
import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { PaymentStepLevel } from '../Dashboard/Dashboard.metadata'

import { TransactionStatus, HowToPayTypes } from '../../utils/constant'
import * as TransferPaymentTypes from '../../redux/types/transfer-payment.type'
import {
  setStepLevel as setStepLevelFnc,
  setTransactionStatus as setTransactionStatusFnc,
} from '../../redux/actions'
import { makeTransferPayment as makeTransferPaymentFnc } from '../../redux/actions/transfer-payment'

import './PaymentProcessing.styles.scss'

import VerificationLoader from '../../components/VerificationLoader'

interface IPaymentProcessingProps {
  makeTransferPayment: any
  setStepLevel: any
  setTransactionStatus: any
  stepLevel: number
  howToPay: string
  energyCreditsCheckBoxState: boolean
  paymentSetupStep: number
  showPaymentModalFromTest?: boolean
  communityFeeGlobal: number
  energyCreditFee: number
  processingFee: number
  transactionStatus: string
}

const PaymentProcessing = ({
  makeTransferPayment,
  setStepLevel,
  setTransactionStatus,
  stepLevel,
  energyCreditsCheckBoxState,
  howToPay,
  paymentSetupStep,
  showPaymentModalFromTest,
  communityFeeGlobal,
  transactionStatus,
  energyCreditFee,
  processingFee,
}: IPaymentProcessingProps): ReactElement => {
  const sumTotalWithFeeIncent = () =>
    Math.round((energyCreditFee + communityFeeGlobal) * 100 + processingFee)
  const { TRANSFER_PAYMENT_SUCCESS } = TransferPaymentTypes

  const handleTransferPayment = () => {
    makeTransferPayment({ amountCents: sumTotalWithFeeIncent() }).then(async (response: any) => {
      if (response?.type === TRANSFER_PAYMENT_SUCCESS) {
        setTransactionStatus(TransactionStatus.completed)
        setStepLevel(PaymentStepLevel.Confirmation)
      } else {
        setTransactionStatus(TransactionStatus.failed)
        setStepLevel(PaymentStepLevel.Confirmation)
      }
    })
  }

  useEffect(() => {
    if (howToPay === HowToPayTypes.EnergyCredit) {
      handleTransferPayment()
    }
  }, [])

  return (
    <>
      <Row>
        <Col span={24}>{howToPay === HowToPayTypes.EnergyCredit && <VerificationLoader />}</Col>
      </Row>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setStepLevel: (setStep: number) => dispatch(setStepLevelFnc(setStep)),
  setTransactionStatus: (transactionStatus: string) =>
    dispatch(setTransactionStatusFnc(transactionStatus)),
  makeTransferPayment: (payload: { amountCents: number }) =>
    dispatch(makeTransferPaymentFnc(payload)),
})

const mapStateToProps = ({
  setStepReducer,
  transferPaymentReducer,
  paymentValuesReducer,
}: {
  setStepReducer: {
    stepLevel: number
    paymentSetupStep: number
    howToPay: string
    energyCreditsCheckBoxState: boolean
    communityFeeCheckBoxState: boolean
  }
  paymentValuesReducer: {
    communityFee: number
    energyCreditFee: number
    processingFee: number
    transactionStatus: string
  }
  transferPaymentReducer: {
    transferPaymentResponse: any
    isLoading: boolean
  }
  errorReducer: { error: any; type: string }
}) => {
  const {
    stepLevel,
    paymentSetupStep,
    energyCreditsCheckBoxState,
    howToPay,
    communityFeeCheckBoxState,
  } = setStepReducer
  const {
    communityFee: communityFeeGlobal,
    energyCreditFee,
    processingFee,
    transactionStatus,
  } = paymentValuesReducer
  const { transferPaymentResponse, isLoading } = transferPaymentReducer

  return {
    stepLevel,
    howToPay,
    energyCreditsCheckBoxState,
    paymentSetupStep,
    communityFeeGlobal,
    energyCreditFee,
    processingFee,
    transactionStatus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessing)
