import { CancelToken } from 'axios'
import { Action, Dispatch } from 'redux'
import { calculateLineItem } from '../../api/calculate-line-item.api'
import { ICalculateLineItemOptions } from '../../models/facility-unit.model'
import { CALCULATE_LINE_ITEM_TYPE } from '../types'

const {
  CALCULATE_LINE_ITEM_FAILURE,
  CALCULATE_LINE_ITEM_REQUEST,
  CALCULATE_LINE_ITEM_SUCCESS,
} = CALCULATE_LINE_ITEM_TYPE

const calculateLineItemPayload = (calculateLineItems: any) => ({
  type: CALCULATE_LINE_ITEM_SUCCESS,
  payload: calculateLineItems,
})

export const calculateLineItemAction = (
  options: ICalculateLineItemOptions,
  source: { token: CancelToken; cancel: () => void },
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: CALCULATE_LINE_ITEM_REQUEST })
    return calculateLineItem(options, source)
      .then((response) => {
        const calculateLineItemData = response
        return dispatch(calculateLineItemPayload(calculateLineItemData))
      })
      .catch((error) => {
        dispatch({
          type: CALCULATE_LINE_ITEM_FAILURE,
          payload: error,
        })
      })
  }
}
