import { Action, Dispatch } from 'redux'
import { initializePayment } from '../../api/intialize-payment.api'
import { IInitializePayment, IInitializePaymentOption } from '../../models/initialize-payment.model'
import { INITIALIZE_PAYMENT_TYPE } from '../types'

const {
  INITIALIZE_PAYMENT_FAILURE,
  INITIALIZE_PAYMENT_REQUEST,
  INITIALIZE_PAYMENT_SUCCESS,
} = INITIALIZE_PAYMENT_TYPE

const initializePaymentPayload = (initializePaymentData: IInitializePayment) => ({
  type: INITIALIZE_PAYMENT_SUCCESS,
  payload: initializePaymentData,
})

export const initializePaymentAction = (options: IInitializePaymentOption) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: INITIALIZE_PAYMENT_REQUEST })
    return initializePayment(options)
      .then((response) => {
        const initializePaymentData = response
        return dispatch(initializePaymentPayload(initializePaymentData))
      })
      .catch((error) => {
        dispatch({
          type: INITIALIZE_PAYMENT_FAILURE,
          payload: error,
        })
      })
  }
}
